import Layout from '@/layout'
const marketing = {
  path: '/market',
  component: Layout,
  name: 'Market',
  meta: {
    title: '营销管理',
    icon: 'el-icon-goods'
  },
  redirect: '/market/query',
  children: [
    {
      path: 'query',
      component: () => import('@/views/market/query/index'),
      name: 'Query',
      meta: { title: '活动数据查询' }
    },
    {
      path: 'allot',
      component: () => import('@/views/market/allot/index'),
      name: 'Allot',
      meta: { title: '员工派券' }
    },
    {
      path: 'setAllot',
      hidden: true,
      component: () => import('@/views/market/allot/setAllot'),
      name: 'SetAllot',
      meta: { title: '新建分配', parentRoute: 'Allot' }
    },
    {
      path: 'public',
      component: () => import('@/views/market/public/index'),
      name: 'Public',
      meta: { title: '公开派券' }
    },
    {
      path: 'setPublic',
      component: () => import('@/views/market/public/setPublic'),
      name: 'SetPublic',
      hidden: true,
      meta: { title: '设置公开派券', parentRoute: 'Public' }
    },
    {
      path: 'publicWhites',
      component: () => import('@/views/market/public/whites'),
      name: 'PublicWhites',
      hidden: true,
      meta: { title: '白名单设置', parentRoute: 'Public' }
    },
    {
      path: 'publicRecords',
      component: () => import('@/views/market/public/records'),
      name: 'PublicRecords',
      hidden: true,
      meta: { title: '查看数据', parentRoute: 'Public' }
    },
    {
      path: 'setPublicForm',
      component: () => import('@/views/market/public/setForm'),
      name: 'SetPublicForm',
      hidden: true,
      meta: { title: '表单设置', parentRoute: 'Public' }
    },
    {
      path: 'activities',
      component: () => import('@/views/market/activities/index'),
      name: 'Activities',
      meta: { title: '活动管理', role: ['ADMIN'] }
    },
    {
      path: 'setActivity',
      component: () => import('@/views/market/activities/setActivity'),
      name: 'SetActivity',
      hidden: true,
      meta: { title: '活动设置', role: ['ADMIN'], parentRoute: 'Activities' }
    },
    {
      path: 'sendPacket',
      component: () => import('@/views/market/sendPacket/index'),
      name: 'SendPacket',
      meta: { title: '员工派红包' }
    },
    {
      path: 'sendPacketRecords',
      hidden: true,
      component: () => import('@/views/market/sendPacket/records'),
      name: 'SendPacketRecords',
      meta: { title: '查看数据', parentRoute: 'SendPacket' }
    },
    {
      path: 'setSendPacket',
      hidden: true,
      component: () => import('@/views/market/sendPacket/setSendPacket'),
      name: 'SetSendPacket',
      meta: { title: '设置派红包活动', role: ['ADMIN'], parentRoute: 'SendPacket' }
    },
    {
      path: 'setSendPacketForm',
      hidden: true,
      component: () => import('@/views/market/sendPacket/setForm'),
      name: 'SetSendPacketForm',
      meta: { title: '表单设置', parentRoute: 'SendPacket' }
    },
    {
      path: 'sendPacketEmployees',
      hidden: true,
      component: () => import('@/views/market/sendPacket/employees'),
      name: 'SendPacketEmployees',
      meta: { title: '员工管理', parentRoute: 'SendPacket' }
    },
    {
      path: 'refuel',
      component: () => import('@/views/market/refuel/index'),
      name: 'Refuel',
      meta: { title: '加油券管理', role: ['ADMIN'] }
    },
    {
      path: 'vouchers',
      component: () => import('@/views/market/vouchers/index'),
      name: 'Vouchers',
      meta: { title: '代金券管理', role: ['ADMIN'] }
    },
    {
      path: 'cardKey',
      component: () => import('@/views/market/cardKey/index'),
      name: 'CardKey',
      meta: { title: '卡密管理', role: ['ADMIN'] }
    },
    {
      path: 'rewards',
      component: () => import('@/views/market/rewards/index'),
      name: 'Rewards',
      meta: { title: '特邀商户奖励', role: ['ADMIN'] }
    },
    {
      path: 'setReward',
      hidden: true,
      component: () => import('@/views/market/rewards/setReward'),
      name: 'SetReward',
      meta: { title: '设置特邀商户奖励', parentRoute: 'Rewards', role: ['ADMIN'] }
    },
    {
      path: 'guests',
      component: () => import('@/views/market/guests/index'),
      name: 'Guests',
      meta: { title: '特邀活动管理' }
    },
    {
      path: 'setGuest',
      hidden: true,
      component: () => import('@/views/market/guests/setGuest'),
      name: 'SetGuest',
      meta: { title: '设置特邀活动', parentRoute: 'Guests' }
    },
    {
      path: 'payment',
      component: () => import('@/views/market/payment/index'),
      name: 'Payment',
      meta: { title: '支付立减活动' }
    },
    {
      path: 'setPayment',
      component: () => import('@/views/market/payment/setPayment'),
      name: 'SetPayment',
      hidden: true,
      meta: { title: '设置支付立减活动', parentRoute: 'Payment' }
    },
    {
      path: 'adv',
      component: () => import('@/views/market/adv/index'),
      name: 'Adv',
      meta: { title: '图文广告设置' }
    },
    {
      path: 'giftActivities',
      component: () => import('@/views/market/gift/index'),
      name: 'GiftActivities',
      meta: { title: '礼品活动' }
    },
    {
      path: 'setGiftActivity',
      component: () => import('@/views/market/gift/setGiftActivity'),
      name: 'SetGiftActivity',
      hidden: true,
      meta: { title: '设置礼品活动', parentRoute: 'GiftActivities' }
    },
    {
      path: 'gifts',
      component: () => import('@/views/market/gift/gifts'),
      name: 'Gifts',
      hidden: true,
      meta: { title: '礼品管理', parentRoute: 'GiftActivities' }
    },
    {
      path: 'setGift',
      component: () => import('@/views/market/gift/setGift'),
      name: 'SetGift',
      hidden: true,
      meta: { title: '设置礼品', parentRoute: 'Gifts' }
    },
    {
      path: 'giftOrders',
      component: () => import('@/views/market/gift/orders'),
      name: 'GiftOrders',
      hidden: true,
      meta: { title: '订单管理', parentRoute: 'Gifts' }
    },
    {
      path: 'packet',
      component: () => import('@/views/market/packet/index'),
      name: 'Packet',
      meta: { title: '红包活动' }
    },
    {
      path: 'setPacket',
      component: () => import('@/views/market/packet/setPacket'),
      name: 'SetPacket',
      hidden: true,
      meta: { title: '设置红包活动', parentRoute: 'Packet' }
    },
    {
      path: 'packetCoustomers',
      component: () => import('@/views/market/packet/coustomers'),
      name: 'PacketCoustomers',
      hidden: true,
      meta: { title: '客户管理', parentRoute: 'Packet' }
    },
    {
      path: 'packetBlacks',
      component: () => import('@/views/market/packet/blacks'),
      name: 'PacketBlacks',
      hidden: true,
      meta: { title: '黑名单', parentRoute: 'Packet' }
    },
    {
      path: 'research',
      component: () => import('@/views/market/research/index'),
      name: 'Research',
      meta: { title: '调研活动' }
    },
    {
      path: 'setResearch',
      hidden: true,
      component: () => import('@/views/market/research/setResearch'),
      name: 'SetResearch',
      meta: { title: '设置调研活动', parentRoute: 'Research' }
    },
    {
      path: 'researchNets',
      hidden: true,
      component: () => import('@/views/market/research/researchNets'),
      name: 'ResearchNets',
      meta: { title: '网点管理', parentRoute: 'Research', role: ['ADMIN'] }
    },

    {
      path: 'memberAppointment',
      component: () => import('@/views/market/memberAppointment/index'),
      name: 'MemberAppointment',
      meta: { title: '预约购礼品' }
    },
    {
      path: 'memberAppointmentLogistics',
      hidden: true,
      component: () => import('@/views/market/memberAppointment/logistics'),
      name: 'MemberAppointmentLogistics',
      meta: { title: '物流管理', parentRoute: 'MemberAppointment' }
    },
    {
      path: 'setMemberAppointment',
      hidden: true,
      component: () => import('@/views/market/memberAppointment/set'),
      name: 'SetMemberAppointment',
      meta: { title: '设置预约购礼品', parentRoute: 'MemberAppointment', role: ['ADMIN'] }
    },
    {
      path: 'memberAppointmentOrder',
      hidden: true,
      component: () => import('@/views/market/memberAppointment/order'),
      name: 'MemberAppointmentOrder',
      meta: { title: '订单列表', parentRoute: 'MemberAppointment' }
    },

    {
      path: 'memberAppointmentGoods',
      hidden: true,
      component: () => import('@/views/market/memberAppointment/goods'),
      name: 'MemberAppointmentGoods',
      meta: { title: '商品列表', parentRoute: 'MemberAppointment', role: ['ADMIN'] }
    },
    {
      path: 'memberAppointmentNet',
      hidden: true,
      component: () => import('@/views/market/memberAppointment/net'),
      name: 'MemberAppointmentNet',
      meta: { title: '网点管理', parentRoute: 'MemberAppointment' }
    },
    {
      path: 'memberAppointmentRecord',
      hidden: true,
      component: () => import('@/views/market/memberAppointment/record'),
      name: 'MemberAppointmentRecord',
      meta: { title: '查看数据', parentRoute: 'MemberAppointment' }
    },
    {
      path: 'lucky',
      component: () => import('@/views/market/lucky/index'),
      name: 'Lucky',
      meta: { title: '抽奖活动' }
    },
    {
      path: 'setLucky',
      component: () => import('@/views/market/lucky/setLucky'),
      name: 'SetLucky',
      hidden: true,
      meta: { title: '设置抽奖活动', parentRoute: 'Lucky' }
    },
    {
      path: 'luckyRecords',
      hidden: true,
      component: () => import('@/views/market/lucky/records'),
      name: 'LuckyRecords',
      meta: { title: '查看数据', parentRoute: 'Lucky' }
    },
    {
      path: 'luckyWhite',
      component: () => import('@/views/market/lucky/white'),
      name: 'LuckyWhite',
      hidden: true,
      meta: { title: '白名单管理', parentRoute: 'Lucky' }
    },
    {
      path: 'luckyLogistics',
      component: () => import('@/views/market/lucky/logistics'),
      hidden: true,
      name: 'LuckyLogistics',
      meta: { title: '物流管理', parentRoute: 'Lucky' }
    },
    {
      path: 'questionLucky',
      component: () => import('@/views/market/questionLucky/index'),
      name: 'QuestionLucky',
      meta: { title: '问卷抽奖' }
    },
    {
      path: 'setQuestionLucky',
      component: () => import('@/views/market/questionLucky/setLucky'),
      name: 'SetQuestionLucky',
      hidden: true,
      meta: { title: '设置问卷抽奖', parentRoute: 'QuestionLucky' }
    },
    {
      path: 'questionLuckyRecords',
      hidden: true,
      component: () => import('@/views/market/questionLucky/records'),
      name: 'QuestionLuckyRecords',
      meta: { title: '查看数据', parentRoute: 'QuestionLucky' }
    },
    {
      path: 'questionLuckyGoods',
      hidden: true,
      component: () => import('@/views/market/questionLucky/goods'),
      name: 'QuestionLuckyGoods',
      meta: { title: '奖品设置', parentRoute: 'QuestionLucky' }
    },
    {
      path: 'questionLuckyTopics',
      hidden: true,
      component: () => import('@/views/market/questionLucky/topics'),
      name: 'QuestionLuckyTopics',
      meta: { title: '问卷设置', parentRoute: 'QuestionLucky' }
    },
    {
      path: 'questionnaire',
      hidden: true,
      component: () => import('@/views/market/questionnaire/index'),
      name: 'Questionnaire',
      meta: { title: '答题问卷' }
    },
    {
      path: 'setQuestionnaire',
      component: () => import('@/views/market/questionnaire/setLucky'),
      name: 'SetQuestionnaire',
      hidden: true,
      meta: { title: '设置答题问卷', parentRoute: 'Questionnaire' }
    },
    {
      path: 'questionnaireRecords',
      hidden: true,
      component: () => import('@/views/market/questionnaire/records'),
      name: 'QuestionnaireRecords',
      meta: { title: '查看数据', parentRoute: 'Questionnaire' }
    },
    {
      path: 'questionnaireGoods',
      hidden: true,
      component: () => import('@/views/market/questionnaire/goods'),
      name: 'QuestionnaireGoods',
      meta: { title: '奖品设置', parentRoute: 'Questionnaire' }
    },
    {
      path: 'questionnaireTopics',
      hidden: true,
      component: () => import('@/views/market/questionnaire/topics'),
      name: 'QuestionnaireTopics',
      meta: { title: '答题问卷设置', parentRoute: 'Questionnaire' }
    },
    {
      path: 'extract',
      component: () => import('@/views/market/extract/index'),
      name: 'Extract',
      meta: { title: '微信提现活动' }
    },
    {
      path: 'setExtract',
      component: () => import('@/views/market/extract/set'),
      name: 'SetExtract',
      hidden: true,
      meta: { title: '设置活动', parentRoute: 'Extract' }
    },
    {
      path: 'extractRecords',
      component: () => import('@/views/market/extract/records'),
      name: 'ExtractRecords',
      hidden: true,
      meta: { title: '查看数据', parentRoute: 'Extract' }
    },
    {
      path: 'memberLucky',
      component: () => import('@/views/market/memberLucky/index'),
      name: 'MemberLucky',
      meta: { title: '集团会员抽奖活动' }
    },
    {
      path: 'setMemberLucky',
      component: () => import('@/views/market/memberLucky/setLucky'),
      name: 'SetMemberLucky',
      hidden: true,
      meta: { title: '设置集团会员抽奖活动', parentRoute: 'MemberLucky', role: ['ADMIN'] }
    },
    {
      path: 'memberLuckyRecord',
      hidden: true,
      component: () => import('@/views/market/memberLucky/record'),
      name: 'MemberLuckyRecord',
      meta: { title: '查看数据', parentRoute: 'MemberLucky' }
    },
    {
      path: 'memberLuckyEmployees',
      hidden: true,
      component: () => import('@/views/market/memberLucky/employees'),
      name: 'MemberLuckyEmployees',
      meta: { title: '员工管理', parentRoute: 'MemberLucky', role: ['ADMIN'] }
    },
    {
      path: 'car',
      component: () => import('@/views/market/car/index'),
      name: 'Car',
      meta: { title: '车主活动' }
    },
    {
      path: 'setcar',
      hidden: true,
      component: () => import('@/views/market/car/setCar'),
      name: 'SetCar',
      meta: { title: '设置车主活动', parentRoute: 'Car' }
    },
    {
      path: 'carNets',
      hidden: true,
      component: () => import('@/views/market/car/carNets'),
      name: 'CarNets',
      meta: { title: '网点管理', parentRoute: 'Car' }
    },
    {
      path: 'query2',
      component: () => import('@/views/market/query2/index'),
      name: 'Query2',
      meta: { title: '活动数据查询（旧版）', role: ['ADMIN'] }
    },
    {
      path: 'appointments',
      component: () => import('@/views/market/appointments/index'),
      name: 'Appointments',
      meta: { title: '预约活动' }
    },
    {
      path: 'appointmentRecords',
      hidden: true,
      component: () => import('@/views/market/appointments/records'),
      name: 'AppointmentRecords',
      meta: { title: '查看数据', parentRoute: 'Appointments' }
    },
    {
      path: 'setApponitment',
      hidden: true,
      component: () => import('@/views/market/appointments/setApponitment'),
      name: 'SetApponitment',
      meta: { title: '设置预约活动', parentRoute: 'Appointments' }
    },
    {
      path: 'apponitmentTopics',
      hidden: true,
      component: () => import('@/views/market/appointments/topics'),
      name: 'ApponitmentTopics',
      meta: { title: '题目列表', parentRoute: 'Appointments' }
    },
    {
      path: 'apponitmentSetTopic',
      hidden: true,
      component: () => import('@/views/market/appointments/setTopic'),
      name: 'ApponitmentSetTopic',
      meta: { title: '设置题目', parentRoute: 'Appointments' }
    },
    {
      path: 'prefectureQuery',
      component: () => import('@/views/market/prefectureQuery/index'),
      name: 'PrefectureQuery',
      meta: { title: '专区客户查询接口', role: ['ADMIN', 'MANAGE_CITY'], area: '广东广州' }
    },
    {
      path: 'business',
      component: () => import('@/views/market/business/index'),
      name: 'Business',
      meta: { title: '业务推荐' }
    },
    {
      path: 'setBusiness',
      hidden: true,
      component: () => import('@/views/market/business/setBusiness'),
      name: 'SetBusiness',
      meta: { title: '编辑业务推荐活动', parentRoute: 'Business' }
    },
    {
      path: 'businessRecord',
      hidden: true,
      component: () => import('@/views/market/business/record'),
      name: 'BusinessRecord',
      meta: { title: '查看数据', parentRoute: 'Business' }
    },
    {
      path: 'sutPay',
      component: () => import('@/views/market/sutPay/index'),
      name: 'SutPay',
      meta: { title: '支付宝活动' }
    }
  ]
}

export default marketing
