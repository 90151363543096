import Layout from '@/layout'
const equity = {
  path: '/car',
  component: Layout,
  name: 'car',
  meta: {
    title: '车主权益',
    icon: 'el-icon-medal',
    role: ['ADMIN']
  },
  redirect: '/car/equities',
  children: [
    {
      path: 'carEquities',
      component: () => import('@/views/car/equities/index'),
      name: 'carEquities',
      meta: { title: '权益列表', role: ['ADMIN'] }
    },
    {
      path: 'setCarEquity',
      hidden: true,
      component: () => import('@/views/car/equities/setEquity'),
      name: 'SetCarEquity',
      meta: { title: '车主权益设置', role: ['ADMIN'], parentRoute: 'carEquities' }
    },
    {
      path: 'carEquityRecord',
      hidden: true,
      component: () => import('@/views/car/equities/record'),
      name: 'CarEquityRecord',
      meta: { title: '查看数据', role: ['ADMIN'], parentRoute: 'carEquities' }
    },
    {
      path: 'carMember',
      component: () => import('@/views/car/member/index'),
      name: 'CarMember',
      meta: { title: '车主会员管理', role: ['ADMIN'] }
    }
  ]
}
export default equity
