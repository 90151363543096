import { setStorage, getStorage } from '@/utils'
const app = {
  namespaced: true,
  state: {
    sidebar: {
      isCollapse: getStorage('isCollapse') ? getStorage('isCollapse') : false
    }
  },
  mutations: {
    TOGGLE_SIDEBAR (state) {
      state.sidebar.isCollapse = !state.sidebar.isCollapse
      setStorage('isCollapse', state.sidebar.isCollapse)
    }
  },
  actions: {
    toggleSideBar ({ commit }) {
      commit('TOGGLE_SIDEBAR')
    }
  }
}

export default app
