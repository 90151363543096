<template>
  <div class="app-main">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {

    }
  },
  props: {

  },
  components: {
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.app-main {
  margin: 15px;
  height: 100%;
  padding: 20px 16px;
  border-radius: 4px;
  background: #ffffff;
}
</style>
