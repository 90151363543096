import Layout from '@/layout'
const equity = {
  path: '/knight',
  component: Layout,
  name: 'Knight',
  meta: {
    title: '自邮骑士会员',
    icon: 'el-icon-medal',
    role: ['ADMIN']
  },
  redirect: '/knight/equities',
  children: [
    {
      path: 'knightEquities',
      component: () => import('@/views/knight/equities/index'),
      name: 'KnightEquities',
      meta: { title: '骑士权益', role: ['ADMIN'] }
    },
    {
      path: 'setKnightEquity',
      hidden: true,
      component: () => import('@/views/knight/equities/setEquity'),
      name: 'SetKnightEquity',
      meta: { title: '骑士权益设置', role: ['ADMIN'], parentRoute: 'KnightEquities' }
    },
    {
      path: 'knightEquityRecord',
      hidden: true,
      component: () => import('@/views/knight/equities/record'),
      name: 'KnightEquityRecord',
      meta: { title: '查看数据', role: ['ADMIN'], parentRoute: 'KnightEquities' }
    },
    {
      path: 'knightMember',
      component: () => import('@/views/knight/member/index'),
      name: 'KnightMember',
      meta: { title: '骑士会员管理', role: ['ADMIN'] }
    }
  ]
}
export default equity
