<!--
import { clearInterval } from 'timers';
 * @Author: 咸鱼
 * @Date: 2020-08-24 15:43:54
 * @LastEditTime: 2020-08-28 15:38:59
 * @LastEditors: 咸鱼
 * @Description
 * @FilePath: /vue-element-wyh/src/App.vue
 * @可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div id="app">
    <router-view></router-view>

  </div>
</template>

<script>

export default {
  name: 'app',
  data () {
    return {
      intval: null
    }
  },
  components: {
  },
  mounted () {
    if (process.env.NODE_ENV === 'production') {
      this.intval = setInterval(() => {
        this.$store.dispatch('dowload/getDowloadList')
      }, 5000)
    }
  },
  destroyed () {
    clearInterval(this.intval)
  }
}
</script>

<style>
#app {
  color: #2c3e50;
}
body {
  min-width: 1200px;
}
/* @media screen and (min-width: 960px) {
  body {
    min-width: 1200px;
  }
} */
</style>
