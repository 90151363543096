<template>
  <el-aside :width="isCollapse?'70px':'256px'">
    <div class="logo-wrap">
      <img src="../../../assets/xinxiang-white.png"
           alt=""
           class="logo"
           :style="{width:!isCollapse?'30px':'24px'}">
      <span v-if="!isCollapse"
            :style="{fontSize:Config.titleFontSize+'px'}">本地优惠卡券</span>
    </div>
    <el-menu :default-active="defaultActiveIndex"
             unique-opened
             :collapse="isCollapse"
             class="el-menu-vertical-demo"
             background-color="#191a23"
             :collapse-transition="false"
             mode="vertical"
             text-color="#fff">
      <side-item v-for="(route,index) in routes"
                 :key="index"
                 :item="route"></side-item>
    </el-menu>

  </el-aside>
</template>

<script>
import Config from '@/config'
import SideItem from './SideItem'
export default {
  data () {
    return {
      Config: Config
    }
  },
  components: {
    SideItem
  },
  computed: {
    isCollapse () {
      return this.$store.state.app.sidebar.isCollapse
    },
    routes () {
      const routes = this.$store.state.permission.routes || []
      const routes2 = routes.filter((item) => {
        return !item.hidden
      })
      return routes2
    },
    defaultActiveIndex () {
      const route = this.$route
      const routes = this.$store.state.permission.routes
      if (route.meta && route.meta.parentRoute) {
        const activeRoute = this.getRoute(route.meta.parentRoute, routes)
        if (activeRoute.name) {
          return activeRoute.name
        } else {
          return this.$route.name
        }
      } else {
        return this.$route.name
      }
    }

  },
  methods: {
    getRoute (name, routes) {
      for (const route of routes) {
        if (route.children) {
          if (this.getRoute(name, route.children)) {
            return this.getRoute(name, route.children)
          }
        } else {
          if (route.name === name) {
            return route
          }
        }
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" >
</style>
