/*
 * @Author: 咸鱼
 * @Date: 2020-07-20 14:36:05
 * @LastEditTime: 2020-08-28 15:33:38
 * @LastEditors: 咸鱼
 * @Description:
 * @FilePath: /vue-element-wyh/src/api/index.js
 * @可以输入预定的版权声明、个性签名、空行等
 */
import request from '@/utils/request'

export const fetchArea = async (data) => {
  return request({
    url: '/api/mall/get/area/info',
    method: 'get',
    data
  })
}
export const getCos = async (data) => {
  return request({
    url: '/api/mall/get/cos/authorization',
    method: 'get',
    data
  })
}
// 图片上传

export function imgUpload (data) {
  return request({
    url: '/api/mall/upload/images',
    method: 'post',
    data
  })
}
// 获取分类列表
export const fetchCates = async (data) => {
  return request({
    url: '/api/mall/admin/category/get/list',
    method: 'get',
    data
  })
}
// 获取三级联动数据
export const fetchAddress = async (data) => {
  return request({
    url: '/api/get/address/info',
    method: 'get',
    data
  })
}
// 获取小程序场景二维码
export const createMpCode = async (params) => {
  return request({
    url: '/api/mall/qr/code/mini/scene/get',
    method: 'get',
    params
  })
}
// 提交一个导出任务
export function exportSubmit (data) {
  return request({
    url: '/api/mall/admin/export/submit',
    method: 'post',
    data
  })
}
// 获取导出任务状态
export function exportStatus (params) {
  return request({
    url: '/api/mall/admin/export/get/status',
    method: 'get',
    params
  })
}
// 提交导入任务
export function fileUpload (data) {
  return request({
    url: '/api/mall/admin/import/submit',
    method: 'post',
    data
  })
}
// 查询导入状态
export function getUploadStatus (params) {
  return request({
    url: '/api/mall/admin/import/get/status',
    method: 'get',
    params
  })
}
// 获取当前导出列表
export function getDowloadList (params) {
  return request({
    url: '/api/mall/admin/export/get/export/list',
    method: 'get',
    params
  })
}
