<!--
 * @Author: 咸鱼
 * @Date: 2020-08-28 14:34:45
 * @LastEditTime: 2020-09-02 15:41:05
 * @LastEditors: 咸鱼
 * @Description:
 * @FilePath: /vue-element-wyh/src/layout/components/Drawer/index.vue
 * @可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="container">
    <el-drawer title="下载列表"
               :with-header="false"
               size="500px"
               :visible.sync="drawer"
               direction="rtl">
      <div class="scroll">
        <div class="title">
          下载列表
        </div>
        <div class="drawer-content">
          <div class="dowload-list"
               v-if="dowloadList.length>0">
            <div class="dowload-item"
                 v-for="(item,index) in dowloadList"
                 :key="index">
              <div class="content">
                <div class="header">
                  <a class="name"
                     v-if="item.status === 'NORMAL'"
                     :href="item.url">{{item.name}}</a>
                  <span class="name"
                        style="color:#666666"
                        v-else>{{item.name}}</span>
                  <div class="status">
                    <el-badge is-dot
                              :type="badgeType[item.status]"
                              class="mr-15"></el-badge>{{item.status | stautsName}}
                  </div>
                </div>
                <div class="process-wrap">
                  <div class="process">
                    <el-progress :percentage="item.percentage"
                                 :color="customColorMethod"></el-progress>
                  </div>
                  <!-- <div class="option">
                <a :href="item.url"
                   v-if="item.status === 'NORMAL'">下载文件</a>
              </div> -->
                </div>
                <div class="time">
                  提交时间：{{item.time}}
                </div>
              </div>
              <div class="dowload-icon">
                <a v-if="item.status === 'NORMAL'"
                   style="color:#409eff"
                   :href="item.url"><i class="el-icon-download"></i></a>
                <i class="el-icon-loading"
                   v-else-if="item.status==='PENDING'"></i>
                <i class="el-icon-el-icon-refresh-left"
                   v-else></i>
              </div>

            </div>
          </div>
          <div class="data-none"
               v-else>
            <i class="el-icon-folder-remove icon"></i>
            <div class="text">当前暂无下载</div>
          </div>
        </div>
      </div>

    </el-drawer>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      drawer: false,
      list: [],
      badgeType: {
        NORMAL: 'success',
        PENDING: 'warning',
        SUBMITED: 'primary',
        FAIL: 'danger'

      }
    }
  },
  components: {
  },
  filters: {
    stautsName (val) {
      switch (val) {
        case 'NORMAL':
          return '下载完成'
        case 'PENDING':
          return '下载中'
        case 'SUBMITED':
          return '提交中'
        case 'FAIL':
          return '下载失败'
        default:
          break
      }
    }
  },
  watch: {

  },
  computed: {
    dowloadList () {
      return this.$store.state.dowload.dowloadList
    }
  },
  methods: {
    customColorMethod (percentage) {
      if (percentage < 30) {
        return '#909399'
      } else if (percentage < 100) {
        return '#e6a23c'
      } else {
        return '#67c23a'
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
}
.drawer-content {
  margin-top: 15px;
  font-size: 14px;
  .dowload-list {
    .dowload-item {
      border: 1px solid #f7f7f7;
      margin-bottom: 15px;
      padding: 15px;
      display: flex;
      box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.16);
      text-decoration: none;
      .content {
        flex: 1;
        .header {
          display: flex;
          .name {
            text-decoration: none;
            color: #409eff;
            flex: 1;
            border-right: 1px solid #e3e3e3;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 300px;
          }
          .status {
            font-size: 14px;
            margin-left: 10px;
          }
        }
        .process-wrap {
          margin-top: 15px;
          display: flex;
          font-size: 14px;
          align-items: center;
          .process {
            flex: 1;
          }
          .option {
            a {
              text-decoration: none;
            }
            font-size: 12px;
            width: 70px;
          }
        }
        .time {
          margin-top: 15px;
          color: #666666;
          font-size: 12px;
        }
      }
      .dowload-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        padding: 0 10px 0 15px;
      }
    }
  }
}
.data-none {
  text-align: center;
  color: #666666;
  .icon {
    font-size: 30px;
  }
  .text {
    margin-top: 15px;
    font-size: 14px;
  }
}
</style>
