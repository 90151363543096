import { fetchArea, fetchAddress } from '@/api'
const area = {
  namespaced: true,
  state: {
    area: [],
    address: []
  },
  mutations: {
    SET_AREA (state, data) {
      state.area = data
    },
    SET_ADDRESS (state, data) {
      state.address = data
    }
  },
  actions: {
    getArea ({ commit, state }) {
      return new Promise((resolve) => {
        if (state.area.length > 0) {
          resolve(state.area)
        } else {
          fetchArea().then((res) => {
            commit('SET_AREA', res.data.address)
            resolve(res.data.address)
          })
        }
      })
    },
    getAddress ({ commit, state }) {
      return new Promise((resolve) => {
        if (state.address.length > 0) {
          resolve(state.address)
        } else {
          fetchAddress().then((res) => {
            commit('SET_ADDRESS', res.data.address)
            resolve(res.data.address)
          })
        }
      })
    }
  }
}

export default area
