import Layout from '@/layout'
const user = {
  path: '/user',
  component: Layout,
  name: 'User',
  meta: {
    title: '用户管理',
    icon: 'el-icon-user',
    role: ['ADMIN']
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/user/index'),
      name: 'UserList',
      meta: { title: '用户列表' }
    }
  ]
}

export default user
