/*
 * @Author: 咸鱼
 * @Date: 2020-08-28 15:31:12
 * @LastEditTime: 2020-08-28 19:16:40
 * @LastEditors: 咸鱼
 * @Description:
 * @FilePath: /vue-element-wyh/src/store/modules/dowload.js
 * @可以输入预定的版权声明、个性签名、空行等
 */
import { getDowloadList } from '@/api'
const dowload = {
  namespaced: true,
  state: {
    dowloadList: [],
    downloadCount: 0
  },
  mutations: {
    SET_DOWLOAD_LIST (state, data) {
      state.dowloadList = data
    },
    SET_DOWNLOAD_COUNT (state, data) {
      state.downloadCount = data
    }
  },
  actions: {
    getDowloadList ({ commit, state }) {
      return new Promise((resolve) => {
        getDowloadList().then((res) => {
          if (res.meta.code === 0) {
            const list = []
            res.data.exports.map((item) => {
              if (item.now_export) {
                list.push({
                  status: item.now_export.status,
                  name: item.file_name,
                  url: null,
                  time: item.now_export.submited_at,
                  percentage: ((parseInt(item.now_export.run_total) / parseInt(item.now_export.total)).toFixed(0) * 100)
                })
              } else {
                list.push({
                  status: item.export_data.status,
                  name: item.file_name,
                  url: item.export_data.download_url,
                  time: item.export_data.submited_at,
                  percentage: 100
                })
              }
            })
            const count = list.filter((item) => {
              return item.status === 'PENDING'
            }).length
            commit('SET_DOWNLOAD_COUNT', count)
            commit('SET_DOWLOAD_LIST', list)
            resolve(res)
          }
        })
      })
    }
  }
}

export default dowload
