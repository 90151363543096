<template>
  <div>
    <el-submenu :index="item.name">
      <template slot="title">
        <i :class="[item.meta.icon]"></i>
        <span>{{item.meta.title}}</span>
      </template>
      <template v-for="(route,index) in item.children">
        <el-menu-item :index="route.name"
                      :key="index"
                      v-if="!route.hidden"
                      @click="link(route)">{{route.meta.title}}</el-menu-item>
      </template>
    </el-submenu>
  </div>

</template>

<script>
export default {
  props: {
    item: Object
  },
  data () {
    return {

    }
  },
  components: {
  },
  methods: {
    link (e) {
      this.$router.push({ name: e.name }).catch(() => {

      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" >
</style>
