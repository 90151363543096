/*
 * @Author: 咸鱼
 * @Date: 2020-05-27 15:38:44
 * @LastEditTime: 2020-08-31 18:27:34
 * @LastEditors: 咸鱼
 * @Description:
 * @FilePath: /vue-element-wyh/src/router/modules/constant.js
 * @可以输入预定的版权声明、个性签名、空行等
 */
import Config from '@/config'
import Layout from '@/layout'
const constantRouter = [
  {
    path: '/',
    hidden: true,
    component: Layout,
    redirect: Config.indexRedirectPath
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue'),
    name: 'Page404',
    hidden: true,
    meta: { title: '404' }
  }
]

export default constantRouter
