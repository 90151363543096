import Layout from '@/layout'
const merchant = {
  path: '/merchant',
  component: Layout,
  name: 'Merchant',
  redirect: '/merchant/stores',
  meta: {
    title: '商户管理',
    icon: 'el-icon-office-building'
  },
  children: [
    {
      path: 'companies',
      component: () => import('@/views/merchant/companies/index'),
      name: 'Companies',
      meta: { title: '企业列表', role: ['ADMIN'] }
    },
    {
      path: 'merchants',
      component: () => import('@/views/merchant/merchants/index'),
      name: 'Merchants',
      meta: { title: '商户列表', role: ['ADMIN'] }
    },
    {
      path: 'stores',
      component: () => import('@/views/merchant/stores/index'),
      name: 'Stores',
      meta: { title: '门店列表' }
    },
    {
      path: 'setStore',
      component: () => import('@/views/merchant/stores/setStore'),
      name: 'SetStore',
      hidden: true,
      meta: { title: '门店设置', parentRoute: 'Stores' }
    }

  ]
}

export default merchant
