// 获取后台返回图片id
export const getImgId = (data) => {
  if (Array.isArray(data)) {
    const ids = []
    data.map((item) => {
      ids.push(item.id)
    })
    return ids
  } else {
    if (data) {
      return data.id
    } else {
      return ''
    }
  }
}
export function setStorage (key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}
export function getStorage (key) {
  const data = JSON.parse(localStorage.getItem(key))
  return data
}
/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter (num) {
  return (num / 100 || 0).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
  // return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}
