import Layout from '@/layout'
const adverts = {
  path: '/adverts',
  component: Layout,
  name: 'Adverts',
  meta: {
    title: '广告管理',
    icon: 'el-icon-picture-outline',
    role: ['ADMIN']
  },
  redirect: '/adverts/carousel',
  children: [
    {
      path: 'homeCarousel',
      component: () => import('@/views/adverts/index'),
      name: 'HomeCarousel',
      meta: { title: '首页轮播图', type: 'INDEX_BANNER' }
    },
    {
      path: 'setHomeCarousel',
      component: () => import('@/views/adverts/setAdvert'),
      name: 'SetHomeCarousel',
      hidden: true,
      meta: { title: '设置首页轮播图', type: 'INDEX_BANNER', parentRoute: 'HomeCarousel' }
    },
    {
      path: 'homeActivity',
      component: () => import('@/views/adverts/index'),
      name: 'HomeActivity',
      meta: { title: '首页活动广告', type: 'INDEX_ACTIVITY' }
    },
    {
      path: 'setHomeActivity',
      component: () => import('@/views/adverts/setAdvert'),
      name: 'SetHomeActivity',
      hidden: true,
      meta: { title: '设置首页活动广告', type: 'INDEX_ACTIVITY', parentRoute: 'HomeActivity' }
    },
    {
      path: 'merchantBanner',
      component: () => import('@/views/adverts/index'),
      name: 'MerchantBanner',
      meta: { title: '附近商家头部广告', type: 'MERCHANTS_BANNER' }
    },
    {
      path: 'setMerchantBanner',
      component: () => import('@/views/adverts/setAdvert'),
      name: 'SetMerchantBanner',
      hidden: true,
      meta: { title: '设置附近商家头部广告', type: 'MERCHANTS_BANNER', parentRoute: 'MerchantBanner' }
    },
    {
      path: 'userBanner',
      component: () => import('@/views/adverts/index'),
      name: 'UserBanner',
      meta: { title: '个人中心广告', type: 'USER_BANNER' }
    },
    {
      path: 'setUserBanner',
      component: () => import('@/views/adverts/setAdvert'),
      name: 'SetUserBanner',
      hidden: true,
      meta: { title: '设置个人中心广告', type: 'USER_BANNER', parentRoute: 'UserBanner' }
    }
  ]
}

export default adverts
