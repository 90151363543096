import Layout from '@/layout'
const terrace = {
  path: '/terrace',
  component: Layout,
  name: 'Terrace',
  meta: {
    title: '平台',
    icon: 'el-icon-coin',
    role: ['ADMIN']
  },
  redirect: '/terrace/index',
  children: [
    {
      path: 'index',
      component: () => import('@/views/terrace/index'),
      name: 'Partner',
      meta: { title: '制券方管理', icon: 'ios-cube-outline', role: ['ADMIN'] }
    }
  ]
}

export default terrace
