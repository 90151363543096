import Layout from '@/layout'
const system = {
  path: '/system',
  component: Layout,
  name: 'System',
  meta: {
    title: '系统',
    icon: 'el-icon-setting'
  },
  redirect: '/system/index',
  children: [
    {
      path: 'employees',
      component: () => import('@/views/system/employees/index'),
      name: 'Employees',
      meta: { title: '员工管理' }
    },
    {
      path: 'managers',
      component: () => import('@/views/system/managers/index'),
      name: 'Managers',
      meta: { title: '后台管理用户' }
    },
    {
      path: 'nets',
      component: () => import('@/views/system/nets/index'),
      name: 'Nets',
      meta: { title: '网点管理' }
    },
    {
      path: 'setNet',
      component: () => import('@/views/system/nets/setNet'),
      name: 'SetNet',
      hidden: true,
      meta: { title: '网点设置', parentRoute: 'Nets' }
    }
  ]
}

export default system
