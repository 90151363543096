<template>
  <el-header :style="{padding: 0,height:'64px'}"
             class="layout-header-bar">
    <span class="notice header-trigger"
          @click="toggleCollapse">
      <i :class="['el-icon-s-fold',isCollapse?'rotate':'']"></i>
    </span>
    <span class="notice header-trigger"
          @click="refresh">
      <i class="el-icon-refresh-right"></i>
    </span>
    <span class="bread-crumb">
      <bread></bread>
    </span>

    <div class="header-right">
      <div class="notice header-trigger"
           @click="$refs.drawer.drawer = true">
        <el-badge :value="downloadCount"
                  v-if="downloadCount>0"
                  class="item"><i class="el-icon-folder-opened"></i></el-badge>
        <i class="el-icon-folder-opened"
           v-else></i>

      </div>
      <Drawer ref="drawer" />
      <span class="notice header-trigger"
            @click="windowFull">
        <i class="el-icon-full-screen"></i>
      </span>
      <!-- <span class="notice header-trigger">
        <el-badge is-dot
                  class="item">
          <i class="el-icon-bell"></i>
        </el-badge>
      </span> -->
      <span class="header-trigger">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <el-avatar shape="square"
                       :size="25"
                       :src="squareUrl"></el-avatar>
            {{name}}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">密码修改</el-dropdown-item>
            <el-dropdown-item :command="2">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>

    </div>
    <ChangePassword ref="changePassword" />
  </el-header>
</template>

<script>
import Drawer from '../Drawer/index'
import ChangePassword from './ChangePassword'
import screenfull from 'screenfull'
import Bread from './Breadcrumb'
export default {
  data () {
    return {
      squareUrl: 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
      isDrawer: false
    }
  },
  computed: {
    name () {
      return this.$store.state.user.userInfo ? this.$store.state.user.userInfo.name : ''
    },
    isCollapse () {
      return this.$store.state.app.sidebar.isCollapse
    },
    downloadCount () {
      return this.$store.state.dowload.downloadCount
    }
  },
  components: {
    Bread,
    ChangePassword,
    Drawer
  },
  methods: {
    refresh () {
      window.location.href = ''
    },
    toggleCollapse () {
      this.$store.dispatch('app/toggleSideBar')
    },
    windowFull () {
      if (!screenfull.isEnabled) { // 如果不允许进入全屏，发出不允许提示
        this.$message.error('不支持全屏')
        return false
      }
      screenfull.toggle()
    },
    async handleCommand (e) {
      switch (e) {
        case 1:
          this.$refs.changePassword.showModal()
          break
        case 2:
          await this.$store.dispatch('user/logout')
          this.$router.push(`/login?redirect=${this.$route.fullPath}`)
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss">
.el-header {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 0 12px;
  height: 64px;
  i {
    font-size: 18px;
  }
  .bread-crumb {
    display: inline-block;
    padding: 0 12px;
  }
  .header-right {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex: 1;
    justify-content: flex-end;
    height: 64px;

    .dropdown-content {
      cursor: pointer;
    }
  }
  .header-trigger {
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    padding: 0 12px;
    cursor: pointer;
    &:hover {
      background: #f8f8f9;
    }
  }
  .el-dropdown-link {
    display: flex;
    align-items: center;
    .el-avatar--square {
      margin-right: 10px;
    }
  }
  .rotate {
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
  }
}
</style>
