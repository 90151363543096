/*
 * @Author: 咸鱼
 * @Date: 2020-05-27 15:38:44
 * @LastEditTime: 2020-08-28 15:31:45
 * @LastEditors: 咸鱼
 * @Description:
 * @FilePath: /vue-element-wyh/src/store/index.js
 * @可以输入预定的版权声明、个性签名、空行等
 */
import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import area from './modules/area'
import app from './modules/app'
import dowload from './modules/dowload'
import permission from './modules/permission'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    area,
    permission,
    app,
    dowload
  },
  state: {
  },
  mutations: {
  },
  actions: {
  }
})
