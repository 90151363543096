import { login, logout, getUserInfo } from '@/api/user'
import { resetRouter } from '@/router'
const user = {
  namespaced: true,
  state: {
    userInfo: null,
    role: null,
    areaText: ''
  },
  mutations: {
    // 设置用户信息
    SET_USER_INFO (state, userInfo) {
      // 这里的 `state` 对象是模块的局部状态
      state.userInfo = userInfo
    },
    //  设置角色
    SET_ROLE (state, role) {
      state.role = role
    },
    SET_AREA (state, userInfo) {
      const { province, city, district, net } = userInfo
      state.areaText = (province || '') + (city || '') + (district || '') + (net || '')
    }
  },
  getters: {

  },
  actions: {
    // 登录
    login ({ commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        login(params).then(async res => {
          if (res.meta.code === 0) {
            const { user } = res.data
            resetRouter()
            const { province, city, district, net } = user
            const areaText = (province || '') + (city || '') + (district || '') + (net || '')
            await dispatch('permission/generateRoutes', { role: user.role, areaText }, { root: true })
            commit('SET_USER_INFO', user)
            commit('SET_ROLE', user.role)
            commit('SET_AREA', user)
            resolve(user)
          } else {
            reject(res.meta.msg)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 退出登录
    async logout ({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(res => {
          if (res.meta.code === 0) {
            commit('SET_USER_INFO', null)
            commit('SET_ROLE', null)
            resetRouter()

            // router.replace({ path: '/login', query: { path: path } })
            resolve()
          } else {

          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    getUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          if (res.meta.code === 0) {
            const { user } = res.data
            commit('SET_USER_INFO', user)
            commit('SET_ROLE', user.role)
            commit('SET_AREA', user)
            resolve(res)
          } else {
            resolve(res)
          }
        }).catch(err => {
          reject(err)
        })
      })
    }
  }

}

export default user
