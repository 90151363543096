import store from '@/store'
const permission = {
  inserted (el, binding, vnode) {
    const role = store.state.user.role
    const { value } = binding

    // const roles = store.getters && store.getters.roles

    if (value && value instanceof Array && value.length > 0) {
      const hasPermission = value.includes(role)
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
      // const permissionRoles = value

      // const hasPermission = roles.some(role => {
      //   return permissionRoles.includes(role)
      // })

      // if (!hasPermission) {
      //   el.parentNode && el.parentNode.removeChild(el)
      // }
    } else {
      throw new Error('need roles! Like v-permission="[\'ADMIN\',\'EDITOR\']"')
    }
  }
}
export default permission
