import Layout from '@/layout'
const equity = {
  path: '/equity',
  component: Layout,
  name: 'Equity',
  meta: {
    title: '权益中心',
    icon: 'el-icon-box',
    role: ['ADMIN']
  },
  redirect: '/equity/equities',
  children: [
    {
      path: 'equities',
      component: () => import('@/views/equity/equities/index'),
      name: 'Equities',
      meta: { title: '权益管理', role: ['ADMIN'] }
    },
    {
      path: 'setEquity',
      hidden: true,
      component: () => import('@/views/equity/equities/setEquity'),
      name: 'SetEquity',
      meta: { title: '权益设置', role: ['ADMIN'], parentRoute: 'Equities' }
    },
    {
      path: 'equityRecord',
      hidden: true,
      component: () => import('@/views/equity/equities/record'),
      name: 'EquityRecord',
      meta: { title: '查看数据', role: ['ADMIN'], parentRoute: 'Equities' }
    },
    {
      path: 'tabs',
      component: () => import('@/views/equity/tabs/index'),
      name: 'Tabs',
      meta: { title: '标签管理', role: ['ADMIN'] }
    }
  ]
}
export default equity
