import axios from 'axios'
import router from '@/router'
import { Notification } from 'element-ui'
// create an axios instance
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL : '',
  // `headers` 是即将被发送的自定义请求头
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests

  timeout: 50000 // request timeout
})
// request interceptor
service.interceptors.request.use(

  config => {
    config.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
    config.headers.common.Pragma = 'no-cache'
    config.headers.common['Cache-Control'] = 'no-cache'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.meta.code === 403) {
      router.push('/login')
    } else if (res.meta.code === 500) {
      Notification.error({
        title: '错误',
        message: res.meta.msg,
        duration: 4000
      })
    }

    return res
  },
  error => {
    console.log('err' + error) // for debug

    return Promise.reject(error)
  }
)
export default service
