<template>
  <div :class="['layout',isCollapse?'hide-sidebar':'']">
    <side-bar></side-bar>
    <el-container>
      <nav-bar></nav-bar>
      <app-main></app-main>
    </el-container>
  </div>
</template>
<script>
import AppMain from './components/AppMain/index'
import SideBar from './components/SideBar'
import NavBar from './components/NavBar'

export default {
  name: '',
  data () {
    return {

    }
  },
  components: {
    AppMain,
    SideBar,
    NavBar
  },
  computed: {
    isCollapse () {
      return this.$store.state.app.sidebar.isCollapse
    }
  },
  methods: {

  },
  mounted () {
  }
}
</script>

<style lang="scss" >
</style>
