import router, { constantRoutes, asyncRoutes } from '@/router'

function hasPermission (role, route, areaText) {
  if (route.meta && route.meta.role) {
    if (route.meta.role.includes(role)) {
      if (route.meta.area) {
        if (route.meta.area === areaText) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  } else {
    return true
  }
}

export function filterAsyncRoutes (routes, role, areaText) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(role, tmp, areaText)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, role, areaText)
      }
      res.push(tmp)
    }
  })
  return res
}

const permission = {
  namespaced: true,
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    }
  },
  actions: {
    generateRoutes ({ commit, state }, { role, areaText }) {
      return new Promise(resolve => {
        let accessedRoutes
        if (role === 'ADMIN') {
          accessedRoutes = asyncRoutes || []
        } else {
          accessedRoutes = filterAsyncRoutes(asyncRoutes, role, areaText)
        }
        commit('SET_ROUTES', accessedRoutes)
        router.addRoutes(accessedRoutes)
        router.options.routes = state.routes
        resolve()
      })
    }
    // const accessRoutes = await store.dispatch('permission/generateRoutes', res.data.user.role)
    //
  }

}

export default permission
