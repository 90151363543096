import store from './store'
import router from './router'

router.beforeEach(async (to, from, next) => {
  if (to.path === '/login') {
    // if is logged in, redirect to the home page
    next()
  } else {
    const whiteList = ['/login']
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
      return
    }
    const role = store.state.user.role

    if (role) {
      next()
    } else {
      try {
        const res = await store.dispatch('user/getUserInfo')
        if (res.meta.code === 0) {
          const areaText = store.state.user.areaText
          await store.dispatch('permission/generateRoutes', { role: res.data.user.role, areaText })
          next({ ...to, replace: true })
        } else {
          next(`/login?redirect=${to.path}`)
        }
      } catch (err) {
        console.log(err)
        next(`/login?redirect=${to.path}`)
      }
    }
  }
})

router.afterEach(route => {
})
