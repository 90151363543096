<template>
  <el-breadcrumb separator="/">
    <el-breadcrumb-item v-for="(item,index) in breadList"
                        :key="item.name">
      <router-link :to="item.path"
                   v-if="index!==breadList.length-1">
        {{item.meta.title}}
      </router-link>
      <span v-else>{{item.meta.title}}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'Bread',
  data () {
    return {
      breadList: []
    }
  },
  watch: {
    $route (route) {
      this.getBreadcrumb()
    }
  },
  components: {
  },
  methods: {
    getBreadcrumb () {
      const matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      const routes = this.$router.options.routes
      this.getRoutes(matched, routes)
    },
    getRoutes (matched, routes) {
      const breads = []
      matched.map((item, index) => {
        if (item.meta && item.meta.parentRoute) {
          const res = this.getRoute(item.meta.parentRoute, routes)
          if (res) {
            breads.push(res)
          }
        }
        breads.push(item)
      })
      this.breadList = breads
    },
    getRoute (name, routes) {
      for (const route of routes) {
        if (route.children) {
          if (this.getRoute(name, route.children)) {
            return this.getRoute(name, route.children)
          }
        } else {
          if (route.name === name) {
            return route
          }
        }
      }
    }
  },
  mounted () {
    this.getBreadcrumb()
  }
}
</script>

<style lang="scss" scoped>
</style>
