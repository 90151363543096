import request from '@/utils/request'

// 登录
export const login = async (data) => {
  return request({
    url: '/api/mall/admin/login',
    method: 'post',
    data
  })
}
// 退出登陆
export const logout = async (data) => {
  return request({
    url: '/api/mall/admin/logout',
    method: 'post',
    data
  })
}
// 获取用户信息
export const getUserInfo = async (params) => {
  return request({
    url: '/api/mall/admin/get/user/info',
    method: 'get',
    params
  })
}

// 获取用户列表
export const fetchUsers = async (params) => {
  return request({
    url: '/api/mall/admin/sundry/get/mini/user/list',
    method: 'get',
    params
  })
}
// 解绑微信用户接口
export const removeBind = async (data) => {
  return request({
    url: '/api/mall/admin/sundry/mini/user/unbind',
    method: 'post',
    data
  })
}
// 修改密码接口
export const changePassword = async (data) => {
  return request({
    url: '/api/mall/admin/change/password',
    method: 'post',
    data
  })
}
