<template>
  <div>
    <el-dialog title="密码修改"
               :visible.sync="dialogVisible"
               :modal-append-to-body="false"
               width="30%">
      <el-form :model="form"
               :rules="rules"
               ref="form"
               label-position="top">
        <el-form-item label="原密码"
                      prop="old_password">
          <el-input type="password"
                    v-model="form.old_password"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="输入新密码"
                      prop="new_password">
          <el-input type="password"
                    v-model="form.new_password"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="重复新密码"
                      prop="new_password2">
          <el-input type="password"
                    v-model="form.new_password2"
                    autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submitForm('form')">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { changePassword } from '@/api/user'
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.form.new_password !== '') {
          this.$refs.form.validateField('new_password2')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.new_password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      form: {
        old_password: '',
        new_password: '',
        new_password2: ''
      },
      rules: {
        old_password: [
          { required: true, message: '请输入原始密码', trigger: 'blur' }
        ],
        new_password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        new_password2: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    showModal () {
      this.dialogVisible = true
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await changePassword(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
