import Vue from 'vue'
import VueRouter from 'vue-router'
import constantRouter from './modules/constant'
import terraceRouter from './modules/terrace'
import Equity from './modules/equity'
import merchant from './modules/merchant'
import user from './modules/user'
import market from './modules/market'
import adverts from './modules/adverts'
import system from './modules/system'
import knight from './modules/knight'
import car from './modules/car'
Vue.use(VueRouter)

export const constantRoutes = [
  ...constantRouter
]
export const asyncRoutes = [
  market,
  adverts,
  terraceRouter,
  Equity,
  knight,
  car,
  merchant,
  user,
  system,
  {
    path: '*',
    hidden: true,
    redirect: '/404'
  }
]
const createRouter = () => new VueRouter({
  mode: 'history',
  base: '/mall/admin',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router
